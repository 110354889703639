import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const TOSPage = (props) => (
    <Layout location={props.location}>
      <SEO title="Home" description="Terms of Service"/>
      <h1 style={styles.header}>Terms of Use</h1>
      <div style={styles.body}>
        <p>
          BY USING THIS WEB SITE, YOU AGREE TO THE FOLLOWING TERMS AND CONDITIONS WITHOUT LIMITATION OR QUALIFICATION. 
          PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY BEFORE USING THIS WEB SITE. IF YOU DO NOT AGREE WITH EACH 
          TERM OF USE, YOU ARE NOT GRANTED PERMISSION TO USE THIS WEB SITE.
        </p>
        <p style={styles.subheader}>
          No Warranty
        </p>
        <p>
          The information on this site, including text, graphics, and links, is provided "AS IS". The Coastal Advisory 
          Group does not warrant the accuracy, adequacy, or completeness of this information and expressly disclaims all 
          warranties of any kind, including without limitation warranties of merchantability, fitness for a particular 
          purpose, and freedom from computer virus. Although the information provided on this site is obtained or compiled 
          from sources believed reliable, the Coastal Advisory Group cannot guarantee the accuracy, timeliness or 
          completeness of any information or data made available.  
        </p>
        <p style={styles.subheader}>
          Limitation of Liability
        </p>
        <p>
          Neither the Coastal Advisory Group, nor any of its affiliates, directors, officers or employees, nor any 
          third party vendor will be liable or have any responsibility of any kind for any loss or damage due to 
          failure or interruption of this site, or resulting from the act or omission of any other party involved in 
          making this site or the data contained therein available, or from any other cause relating to your access to, 
          inability to access, or use of the site or these materials, whether or not the circumstances giving rise to 
          such cause may have been within the control of the Coastal Advisory Group or of any vendor providing 
          software or services support. IN NO EVENT WILL THE COASTAL ADVISORY GROUP BE LIABLE FOR ANY DAMAGES, 
          INCLUDING WITHOUT LIMITATION DIRECT OR INDIRECT, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGES, LOSSES OR 
          EXPENSES ARISING IN CONNECTION WITH THIS SITE OR ANY LINKED SITE OR USE THEREOF OR INABILITY TO USE BY ANY 
          PARTY, OR IN CONNECTION WITH ANY FAILURE OF PERFORMANCE, ERROR, OMISSION, INTERRUPTION, DEFECT, DELAY IN 
          OPERATION OR TRANSMISSION, COMPUTER VIRUS OR LINE OR SYSTEM FAILURE, EVEN IF THE COASTAL ADVISORY GROUP, OR 
          REPRESENTATIVES THEREOF, ARE ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, LOSSES OR EXPENSES.
        </p>
        <p style={styles.subheader}>
          No Solicitation or Advice
        </p>
        <p>
          Unless otherwise expressly stated, the information appearing on this site is provided for informational purposes 
          only and does not constitute an offer to sell or buy any product or service that may be referenced or described on 
          this site. If an offer to sell or buy a product or service is expressly offered, the offering is specifically 
          limited to the jurisdiction(s) in which the offeror has the authority to make the offer and be bound. Nothing in 
          this site should be construed as rendering tax, legal, investment or accounting advice.
        </p>
        <p style={styles.subheader}>
          Trademarks and Copyrights
        </p>
        <p>
          All trademarks, service marks, trade names, logos, and icons are proprietary to the Coastal Advisory Group or used 
          with permission obtained by the Coastal Advisory Group. Nothing contained on the site should be construed as 
          granting any license or right to use any trademark displayed on this site without the written permission of the 
          Coastal Advisory Group and/or the owner of the mark, logo or icon. Use of the trademarks displayed on this site, 
          or any other content on this site, except as provided herein, is strictly prohibited. Images displayed on this 
          site are either the property of, or used with permission by, the Coastal Advisory Group. The use of these images 
          by you is prohibited unless specifically permitted by the Coastal Advisory Group and/or the owner. Any 
          unauthorized use of images may violate copyright laws, trademark laws, the laws of privacy and publicity, and 
          communications regulations and statutes.
        </p>
        <p style={styles.subheader}>
          Use of Information and Materials
        </p>
        <p>
          The information and materials contained in these pages, and the terms, conditions, and descriptions that 
          appear, are subject to change. Unauthorized use of the Coastal Advisory Group’s web site and systems, 
          including but not limited to unauthorized entry into the Coastal Advisory Group’s systems, misuse of passwords, 
          or misuse of any information posted on a site, is strictly prohibited. Investment services are not bank deposits 
          or insured by the FDIC or other entity, and are subject to investment risks, including possible loss of principal 
          amount invested.
        </p>
        <p style={styles.subheader}>
          Links
        </p>
        <p>
          THIS WEB SITE MAY BE LINKED TO OTHER WEB SITES NOT MAINTAINED BY THE COASTAL ADVISORY GROUP. THE COASTAL 
          ADVISORY GROUP IS NOT RESPONSIBLE FOR THE CONTENT OF THOSE WEB SITES. THE INCLUSION OF ANY LINK TO SUCH WEB 
          SITES DOES NOT IMPLY APPROVAL OF OR ENDORSEMENT BY THE COASTAL ADVISORY GROUP OF THE WEB SITES OR THE CONTENT 
          THEREOF.
        </p>
        <p style={styles.subheader}>
          Additional Terms
        </p>
        <p>
          Certain sections or pages on this site may contain separate terms and conditions, which are in addition to 
          these terms and conditions. In the event of a conflict, the additional terms and conditions will govern for 
          those sections or pages. 
        </p>
      </div>
    </Layout>
)

let styles={
  header: {
    color: '#8d1b38',
    padding: '35px 15px 0 25px'
  },
  primaryText: {
    color: '#8d1b38'
  },
  bullet: {
    paddingLeft: '30px'
  },
  subheader: {
    color: '#8d1b38',
    fontWeight: '700',
    fontStyle: 'italic'
  },
  body: {
    padding: '0 60px 30px 60px',
    color: 'black'
  }
}

export default TOSPage
